const TABLE_ITEMS_PER_PAGE = 10;

function initTable($table, $pagination) {
	$pagination.find('li:first-child span').on('click', function (event) {
		var currentPage = parseInt($table.find('tbody').data('current-page'));
		if (currentPage > 0) {
			$table.find('tbody').data('current-page', currentPage - 1);
		}

		updateTable($table, $pagination);
	});

	$pagination.find('li:last-child span').on('click', function (event) {
		const currentPage = parseInt($table.find('tbody').data('current-page'));
		const total = parseInt($table.find('tbody').data('total'));
		const pageCount = Math.ceil(total / TABLE_ITEMS_PER_PAGE);

		if (currentPage < pageCount) {
			$table.find('tbody').data('current-page', currentPage + 1);
		}

		updateTable($table, $pagination);
	});

	updateTable($table, $pagination);
}

function updateTable($table, $pagination) {
	const currentPage = parseInt($table.find('tbody').data('current-page'));
	const total = parseInt($table.find('tbody').data('total'));
	const pageCount = Math.ceil(total / TABLE_ITEMS_PER_PAGE);

	$table.find('tr').each(function (index) {
		const min = currentPage * TABLE_ITEMS_PER_PAGE;
		const max = (currentPage + 1) * TABLE_ITEMS_PER_PAGE;

		if (min <= index && index < max) {
			$(this).removeClass('d-none');
		} else {
			$(this).addClass('d-none');
		}
	});

	if (currentPage > 0) {
		$pagination.find('li:first-child span').removeClass('disabled');
	} else {
		$pagination.find('li:first-child span').addClass('disabled');
	}

	if (currentPage < pageCount) {
		$pagination.find('li:last-child span').removeClass('disabled');
	} else {
		$pagination.find('li:last-child span').addClass('disabled');
	}
}

function checkAllRequiredFields() {
	let allCheck = true;
	$('form input').each(function (event) {
		var $this = $(this);
		if ($this.prop('required') && !$this.val()) {
			allCheck = false;
		}
	});
	return allCheck;
}